#setsubscontainer{
	font-size:16px;
	font-weight:normal;
	line-height:1.6em;
    width: 95%;

}

#setsubinternalcontainer{
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
	padding-left: 20;
}


#manageusersinputs:focus{
	border-bottom-color: #FD6262
}

#peoplepicker{
	--input-border-color--focus: #FD6262;
}




