
label{
  font-size: 12px;
  line-height: normal;
  margin-bottom: 0px;
}

h4{

  font-size: 14px;
  font-family: "Segoe UI", system-ui, "Apple Color Emoji", "Segoe UI Emoji", sans-serif;
}

h3{

  font-size: 18px;
  font-family: "Segoe UI", system-ui, "Apple Color Emoji", "Segoe UI Emoji", sans-serif;
}

h2{

  font-size: 28px;
  font-family: "Segoe UI", system-ui, "Apple Color Emoji", "Segoe UI Emoji", sans-serif;
}

p{
  font-size: 14px;
  font-family: "Segoe UI", system-ui, "Apple Color Emoji", "Segoe UI Emoji", sans-serif;
}

.bitbutton{
background-color:#FD6262;
color:#fff;
padding:8px 10px;
border:none;
  border-radius: 5px;
  font-weight: bold;
}

.bitbutton:hover{
background:red;
color:#fff;
  cursor: pointer;
}

.bitbuttonsmall{
background-color:#FD6262;
color:#fff;
padding:4px 5px;
border:none;
  border-radius: 5px;
  font-weight: bold;
  font-size: small;
}

.bitbuttonsmall:hover{
background:red;
color:#fff;
  cursor: pointer;
}

.bitbuttonsmall:disabled{
  background: #6d6c6b;
  cursor: default;
}

.bitbuttoncancel{
  background-color:white;
color:#FD6262;
padding:7px 9px;
  border-color: #6d6c6b;
  border-radius: 5px;
  border-width: 1px;
  font-weight: bold;
  margin-left: 5px;
}

.bitbuttoncancel:hover{
background:red;
color:#fff;
  cursor: pointer;
}

.peoplepicker{
  --input-border: 1px solid #6d6c6b;
}

mgt-login {
  --button-background-color: #FD6262;
  --button-color--hover: white;
  --button-background-color--hover: red;
  --button-border-radius: 5px;
  --button-border-width: 1px;
}
