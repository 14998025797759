#helpcontainer{
	font-size:16px;
	font-weight:normal;
	line-height:1.6em;
	width: 95%;
}

#helpcontainermobile{
	font-size:16px;
	font-weight:normal;
	line-height:1.6em;
	width: 100%;
	height: 100%;
}

#internalhelpcontainer{
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
	padding-left: 20;
}

#internalhelpcontainermobile{
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.ideasForm{
    width: 100%;
	font-size:16px;
	font-weight:normal;
}

.form-group{
	padding-bottom:5px;
}

.ideasForm label{
	display:block;
}

.ideasForm h5{
	margin: 0;
}

.ideasForm select{
	margin-bottom: 10px;
}

.ideaoptions{
	font-size:12px;
	font-weight:normal;
	width: 150px;
	margin-bottom: 3px;
}

.ideasForm input[type="text"], .ideasForm textarea{
	padding:8px;
	width:100%;
	margin-bottom: 5px;
	font-size:14px;
	font-weight:normal;
}

#messagesent{
	color: #FD6262;
	font-size:14px;
	font-weight:normal;
}