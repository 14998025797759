.menu-container{
   padding-right: 0;
}
.ms-Button-icon{
   font-size: 20px;
   color: #FD6262;
}

.menu-chevron{
   color: rgb(253, 98, 98);
   cursor: pointer;
   font-size: 25;
   height: 8px;
   width: 25;
   margin-bottom: 25;
   margin-left: 8;
}

.menu-chevron:hover{
   color: red;
}

.menu-items button {
   height: 40px;
   top: 0px;
}

.menu-items button:hover {
   color: #FD6262;
}

.menu-items button::after{
   border-left: 2px solid #FD6262;
}


