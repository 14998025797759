/* .ms-Nav{
  background-color: aquamarine;
} */

 .ms-Grid-col {
   padding-left: 0;
   margin-left: 0;
 }

 .ms-Viewport {
  margin-right: 0.5%;
}
  
  .ms-FocusZone {
    padding: 0;
  }

  .settingpagecontainer{
    background-color: rgb(204, 204, 204)
  }

  .mgt-person{
    --avatar-size: 30px;
    --font-size: 12px;
  }