#picture
{
    align-self: center;
    height: 200;
    width: 300;
}

#message{
    align-self: center;
}

#basepagecontainer{
	font-size:16px;
	font-weight:normal;
	line-height:1.6em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 60%;
}